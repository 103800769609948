.App {
  text-align: center;
}

.App-logo {
  height: 80vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);

}

.last-img{
  height: 20vh;
  display: flex;
  flex-direction: row;
}
.last-img img{
  height: 20vh;
  display: flex;
  flex-direction: row;
}
.last-img .text{
  margin: 0;
  padding: 0;
  height: 20vh;
  line-height: 22vh;
  background-position: center;
  background-size: contain;
  background-repeat: repeat-x;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
